.background {
  background: url("../images/background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 100vh;
}

.res-form {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding: 36px 24px;
  gap: 12px;
  width: 100%;
  /* height: 558px; */
  background: rgba(242, 242, 242, 0.7);
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
}

.res-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */

  color: #ffffff;
  text-align: justify;
}

.icon {
  padding: 10px;
  min-width: 40px;
}

.input-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 20px;
  gap: 10px;

  width: 100%;
  height: 54px;

  background: #ffffff;
  /* Gray 4 */

  border: 2px solid #bdbdbd;
  border-radius: 8px;
}

.btn-getotp {
  justify-content: center;
  align-items: center;
  padding: 16px 20px;
  gap: 12px;

  height: 56px;

  /* Blue */

  background: #00a7e2;
  border: 1px solid #00a7e2;
  border-radius: 8px;
  width: 100%;
}

.span-getotp {
  font-family: "Signika";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}
.checkbox-tc {
  margin-top: -4px;
  display: flex;
  align-items: center;
}
.label-checkbox {
  margin-top: 6px;
  display: flex;
  align-items: center;
}
.label-checkbox1 {
  margin-top: 6px;
  display: flex;
  align-items: center;
}
.input-checkbox {
  accent-color: #f6e192;
  background: #ffffff;
  border: 1px solid #89939e;
  border-radius: 4px;
  /* float: left; */
  margin-top: 3px;
  margin-right: 5px;
  width: 28px;
  height: 18px;
}
.title-checkbox {
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  text-align: justify;

}

.check-width {
  padding: 0 5px;
}
.bg-text{
  position: absolute;
  top: 24vh;
  z-index: 150;
}
.btn-register {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 20px;
  gap: 10px;
  width: 100%;
  height: 55px;

  background: #8d8d8d;
  border-radius: 8px;
  border: 1px solid #8d8d8d;

  font-family: "Roboto-Bold";
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #ffffff;
}

.btn-register-active {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 2px;
  gap: 10px;

  width: 100%;
  height: 55px;

  background: #285c5c;
  /* Gray 6 */

  border: 2px solid #f2f2f2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;

  font-family: "Roboto-Bold";
  /* font-style: normal; */
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #fff;
}

.btn-danhsachthamgia {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 20px;
  gap: 10px;

  width: 100%;
  height: 48px;

  border: 2px solid rgba(255, 255, 255, 0.8);
  border-radius: 8px;

  /* font-family: "Roboto";
  font-style: normal; */
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
}
.modal {
  top: 20vh;
  overflow: unset;
}

.modal-dialog {
  margin: 0;
  position: absolute;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  padding: 10px;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: transparent;
  background-clip: padding-box;
  border: none !important;
  border-radius: none !important;
  outline: 0;
}
.popup-box:before {
  content: "";
  background: url("../images/tick_icon.png");
  width: 100px;
  height: 100px;
  position: absolute;
  top: -50px;
  background-repeat: no-repeat;
  background-size: contain;
}
.popup-box .close-btn {
  cursor: pointer;
  position: absolute;
  top: -10px;
  right: -8px;
  width: 35px;
}

.popup-box {
  border-radius: 20px;
  background-color: white;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  width: 100%;
  padding: 50px 15px 15px 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.popup-phone {
  font-family: "Roboto-Regular";
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #333333;
}
h3.popup-header {
  color: #cc2023;
  font-size: 26px;
  font-weight: 800;
  font-family: "Roboto-Bold";
}

.popup-message {
  font-family: "Roboto-Regular";
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #4f4f4f;
  transform: translateY(10px);
  margin-bottom: 20px;
}

.popup-btn-ok {
  width: 106px;
  height: 47px;
  background: #cc2023;
  border-radius: 16px;
  border: none;
  font-family: "Roboto-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: white;
  bottom: 0;
  text-align: center;
  /* transform: translateX(-50%); */
}


.checkphone-icons-remove {
  position: absolute;
  top: 70px;
  right: 60px;
  z-index: 99;
  cursor: pointer;
}
@font-face {
  font-family: "Roboto-Bold";
  src: url("../font/Roboto-Bold.ttf");
}
@font-face {
  font-family: "Roboto-BoldItalic";
  src: url("../font/Roboto-BoldItalic.ttf");
}
@font-face {
  font-family: "Roboto-Italic";
  src: url("../font/Roboto-Italic.ttf");
}
@font-face {
  font-family: "Roboto-Light";
  src: url("../font/Roboto-Light.ttf");
}
@font-face {
  font-family: "Roboto-Thin";
  src: url("../font/Roboto-Thin.ttf");
}
@font-face {
  font-family: "Roboto-Medium";
  src: url("../font/Roboto-Medium.ttf");
}
@font-face {
  font-family: "Roboto-Regular";
  src: url("../font/Roboto-Regular.ttf");
}
