.win-title-view {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  text-align: center;

  color: #f6de8b;
}

.info-form {
  text-align: center;
}
span.subtitle.sub-win-title-view {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
}

img.img-win {
  margin-top: 20px;
}

.sub-img-win {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: justify;
  color: #ffffff;
  margin-top: 30px;
}

.wheelresult-btn-luotQuay {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 20px;
  gap: 10px;

  width: 100%;
  height: 48px;

  background: transparent;
  /* border: 2px solid rgba(255, 255, 255, 0.6); */
  border-radius: 8px;
  margin-top: 0px;

  font-family: "Roboto-Bold";
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;

  color: #fff;
}
.wheelresult-btn-nhanqua {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 20px;
  gap: 10px;

  width: 98.5%;
  height: 47.5px;

  background-color: #f6e192;
  /* border: 2px solid rgba(255, 255, 255, 0.6); */
  border-radius: 8px;
  margin-top: 0.9px;

  font-family: "Roboto-Bold";
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;

  color: #2a6767;
}
.title-gift {
  color: #fde893;
}
.name-gift {
  font-family: "Roboto-Bold";
  padding: 0 20px;
  margin-top: -2px;
  font-size: 20px;
}
.checksum-gift {
  margin-top: -4px;
}
.img-gift {
  /* margin-top: 10px; */
}
.scroll-description {
  /* display: block; */
  width: 100%;
  max-height: 100px;
  /* height: 120px; */
  /* max-height: 100%; */
  overflow: scroll;
  /* scrollbar-width: thin; */
}
.containerNotify__background .containerNotify__background-list {
  overflow-y: auto;
  max-height: 260px;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  font-family: "Roboto-Regular";
}
.containerNotify__backgrounds .containerNotify__backgrounds-list {
  overflow-y: scroll;
  max-height: 500px;
  /* margin: 0 -8px; */
  padding: 0 0px;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}



.containerNotify__backgrounds-list::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: #d73f2c;
}
@media (max-width: 320px) {
  .containerNotify__backgrounds .containerNotify__backgrounds-list {
    overflow-y: auto;
    max-height: 400px;
    margin: 0 -8px;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
  }
}
.popup-box-code {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  max-width: 340px;
  width: 100%;
}
.content-popup-box-code {
  position: absolute;
  top: 25px;
  left: 50%;
  transform: translateX(-50%);
}
.content-popup-box-content {
  position: absolute;
  top: 25px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 430px;
  width: 100%;
  padding: 0 35px;
  max-height: 200px;
}
.input-hidden {
  position: absolute;
  background-color: #e0e0e0;
  border-top: 1px solid #bdbdbd;
  left: 10px;
  border: none;
  z-index: 999999;
  text-align: center;
  height: 34px;
}
.input-hidden:focus {
  border: none;
  border-top: 0px solid #c5c5c5;
  box-shadow: 0 0 3px #e0e0e0;
  outline-offset: 0px;
  outline: none;
}
#input-type {
  position: relative;
  z-index: -999999;
}
#label2 {
  accent-color: #f6e192;
}
