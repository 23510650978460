.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App {
  min-height: 100vh !important;
  box-sizing: border-box;
  margin: 0 auto;
  overflow: hidden;
  background: transparent;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@media only screen and (min-width: 1000px) {
  .App {
    max-width: none !important;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.background-yomost {
  /* background-color: #278c12; */
  min-height: 100vh;
  background-color: #d63f2b;
  /* background: linear-gradient(to top left, #f6c071 2%, #f8e48a); */
}
.background-tai {
  /* background-color: #278c12; */
  min-height: 100vh;
  background-color: #ebad30;
  /* background: linear-gradient(to top left, #f6c071 2%, #f8e48a); */
}
.image-home {
  width: 100%;
}
.home-btn-top {
  /* position: absolute;
  top: 55vh; */
  margin-top: 0px;
}
.home-btn-thamgia-bg-ct {
  flex-direction: row;
  justify-content: center;
  /* padding: 16px 24px; */
  gap: 12px;
  width: 100%;
  height: 60px;
  margin-top: 0vh;
  background: #c12528;
  border: 0px solid rgba(255, 255, 255, 0.6);
  border-radius: 8px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}
.home-btn-thamgia-ct {
  flex-direction: row;
  justify-content: center;
  /* padding: 16px 24px; */
  /* gap: 12px; */
  width: 100%;
  height: 56px;
  /* margin-top: 65vh; */
  background: #c12528;
  border: 0px solid rgba(255, 255, 255, 0.6);
  border-radius: 8px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}
.home-btn-thamgia-1,
.home-btn-thamgia-2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 12px;

  width: 100%;
  height: 58px;

  background: #df3b2a;
  border: 0px solid rgba(246, 222, 139, 0.6);
  border-radius: 8px;
  margin-top: 20px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  /* or 100% */

  display: flex;
  align-items: center;
  text-align: center;

  color: #f6de8b;
  text-decoration: none;
}

.home-btn-thamgia-1:hover {
  color: #f6de8b;
}
.footer-bottom {
  margin-top: -5px;
  width: 100%;
}
.bg-result {
  width: 90%;
}
.bground-result {
  position: relative;
}
.bg-content {
  position: absolute;
  width: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.text-content {
  color: #1b8250;
}
.font-roboto {
  font-weight: 700;
  font-size: 32px;
}
.backg-small {
  background: url(./assets/images/bg-small-result.png);
  width: 80%;
  height: 150px;
  padding: 0 10px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.content-voucher {
  width: 78%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  top: 30px;
  color: "#1B8250";
  font-size: 24px;
}
.button-voucher {
  width: 100px;
  height: 45px;
  position: relative;
  top: 40px;
  font-size: 14px;
  background-color: #1b8250;
  color: #fff;
  padding: 4px;
  border-radius: 8px;
  border: 0 solid;
  outline: 3px solid #77ac8d;
}
@media (max-height: 569px) {
  .content-voucher {
    position: relative;
    line-height: 16px;
    top: -16px;
  }
  .button-voucher {
    /* top: 7px;
    left: 50%;
    transform: translateX(-73%); */
  }
}
@media (min-height: 569px) and (max-height: 668px) {
  .content-voucher {
    position: relative;
    line-height: 16px;
    top: 0px;
  }
  .button-voucher {
    top: 5px;
    /*left: 50%;
    transform: translateX(-85%); */
  }
}
.bg-checksum {
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(./assets/images/checksum.png);
  background-repeat: no-repeat;
  width: 165px;
  height: 36px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.grid-gift .grid-gift-list {
  margin-left: 5px;
  overflow-y: scroll;
  max-height: 260px;
  /* margin: 0 -8px; */
  width: 100%;
  min-height: 240px;
  padding: 0 18px;
  overflow: -moz-scrollbars-none;
  overflow-x: hidden;
  -ms-overflow-style: none;
}
.top-title-gift {
  top: 80px;
}
.number-titlegift {
  font-family: "Roboto-Bold";
}
@media (max-width: 320px) {
  .grid-gift .grid-gift-list {
    overflow-y: scroll;
    max-height: 180px;
    min-height: 130px;
    /* margin: 0 -8px; */
    padding: 0 6px;
    overflow: -moz-scrollbars-none;
    overflow-x: hidden;
    -ms-overflow-style: none;
  }
  .top-title-gift {
    top: 80px;
  }
}
.grid-gift .grid-gift-list::-webkit-scrollbar {
  -webkit-appearance: none;
}

.grid-gift .grid-gift-list::-webkit-scrollbar:vertical {
  width: 11px;
}

.grid-gift .grid-gift-list::-webkit-scrollbar:horizontal {
  height: 11px;
}

.grid-gift .grid-gift-list::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: #d73f2c;
}

.bg-denied {
  position: relative;
}

.img-denied {
  width: 80%;
}
.bg-logo-ig {
  margin-top: 0px;
  font-size: 150px;
  font-weight: 800;
  color: #bdbdbd;
}
.locate_start {
  color: #184680;
}
.locate_between {
  color: #3e97d9;
  margin-left: -30px;
}
.locate_last {
  color: #184680;
  margin-left: -32px;
}
.url-text {
  color: black;
  font-weight: 700;
}
.bg-logo {
  margin-top: 50px;
  font-size: 40px;
  font-weight: 600;
  color: #bdbdbd;
}
.bg-title {
  font-size: 30px;
  font-weight: 600;
  color: #184680;
}
.bg-content {
  position: relative;
  top: 25px;
  font-size: 21px;
  text-align: left;
  font-weight: 600;
  color: #184680;
  padding: 0 16px;
}
.reponsive-padding{
  padding: 0 5px;
  width: 90%;
}
@media (min-width: 431px){
  .reponsive-padding{
    padding: 0 10px;
    margin-left: 10px;
    width: 88%;
  }
}
.consent-text{
  text-align: justify;
  font-size: 14px;
}